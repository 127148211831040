import React from 'react';
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import BackButton from '../components/buttons/BackButton.js';
import Cards from "../components/cards/readings/Cards.js";
import DateField from '../components/DateField.js';
import Drawer from '../components/Drawer.js';
import DownloadButton from '../components/buttons/DownloadButton.js';
import FilledButton from '../components/buttons/FilledButton.js';
import FilterIconButton from '../components/buttons/FilterIconButton.js';
import Graphs from "../components/graphs/Graphs.js";

import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import InfoIconButton from '../components/buttons/InfoIconButton.js';
import RadioButton from '../components/RadioButton.js';
import Spinner from '../components/Spinner.js';
import Shadow from '../components/Shadow.js';

import { AuthContext } from '../App';
import { ThemeContext } from '../App';
import { urlContext } from '../App';
import { keyContext } from '../App';

import "./style.css";

const initSite = {
    pk: "",
    sk: "",
    active: false,
    name: "",
    description: "",
    location: {
        latitude: 0,
        longitude: 0
    },
    readings: [],
    address: {
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: ""
    },
    message: {}
}

var lastUpdate = "-/-/- -:-:-"

export default function Detail(props) {
    let sDate = new Date();
    let eDate = new Date();
    eDate.setDate(sDate.getDate() + 7);

    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const asURL = useContext(urlContext);
    const asKey = useContext(keyContext);

    const [endDate, setEndDate] = useState(new Date());
    const [filter, setFilter] = useState(false);
    // const [nextToken, setNextToken] = useState(null);
    const [messages, setMessages] = useState([]);
    const [readings, setReadings] = useState([]);
    const [shadow, setShadow] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const [site, setSite] = useState(initSite);
    const [title, setTitle] = useState("-");

    const navigate = useNavigate();
    const url = window.location.href;
    const siteID = url.split("=")[1];

    const getMessages = async (start, end, token = null) => {
        let messages = []
        let nextToken = null

        const isoStart = start.toISOString().split("T")[0];
        const isoEnd = end.toISOString().split("T")[0];
        let getQuery = {
            query: `query getMessages($id:ID!, $start:AWSDate!, $end:AWSDate!, $nextToken: String){
                getMessages(id:$id, start:$start, end:$end, nextToken: $nextToken) {
                    site {
                        sk,
                        active,
                        description,
                        name,
                        readings {
                            MLLW
                            elevation
                            max
                            min
                            name
                            path
                            type
                            unit
                            thresholds {
                              color
                              name
                              value
                            }
                        },
                        message {
                            id,
                            ina219 {
                                current,
                                voltage
                            },
                            bq27441 {
                                voltage,
                                current,
                                power,
                                capacity
                            },
                            lte {
                                rssi,
                                quality
                            },
                            lufft {
                                airPress {
                                    abs
                                },
                                airTemp,
                                compass,
                                dewPoint,
                                humidity {
                                    abs,
                                    rel,
                                },
                                precip {
                                    abs,
                                    intensity,
                                    isRaining,
                                    type
                                },
                                windDir,
                                windSpeed
                            },
                            senix {
                                mm,
                                temp
                            },
                            maxbotix {
                                mm
                            },
                            csi {
                                basic {
                                    ft
                                }
                            },
                            timestamp
                        }
                    },
                    messages {
                        msg {
                            timestamp,
                            ina219 {
                                current,
                                voltage
                            },
                            bq27441 {
                                voltage,
                                current,
                                power,
                                capacity
                            },
                            lte {
                                rssi,
                                quality
                            },
                            lufft {
                                airPress {
                                    abs
                                },
                                airTemp,
                                compass,
                                dewPoint,
                                humidity {
                                    abs,
                                    rel,
                                },
                                precip {
                                    abs,
                                    intensity,
                                    isRaining,
                                    type
                                },
                                windDir,
                                windSpeed
                            },
                            senix {
                                mm,
                                temp
                            },
                            maxbotix {
                                mm
                            },
                            csi {
                                basic {
                                    ft
                                }
                            }
                        }
                    },
                    nextToken
                }   
            }`,
            variables: {
                id: siteID,
                start: isoStart,
                end: isoEnd,
                nextToken: nextToken,
            }
        }

        console.log("Detail:getMessages:site id:", siteID);
        console.log("Detail:getMessages:start:", isoStart);
        console.log("Detail:getMessages:end:", isoEnd);

        setShadow(true);
        setSpinner(true);

        let resp, result
        let count = 0
        do {
            resp = await fetch(asURL, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/graphql",
                    "x-api-key": asKey
                },
                body: JSON.stringify(getQuery)
            })

            if (!resp.ok) {
                throw new Error(`Response status: ${resp.status}`);
            }

            result = await resp.json();
            console.log("Detail:resp:", result)//.data.getMessages);


            // Set Messages
            // if (result.data.getMessages.messages.length === 0) {
            //     setSpinner(false);
            //     setShadow(false);
            //     return;
            // }
            // setMessages(result.data.getMessages.messages);
            // setNextToken(result.data.getMessages.nextToken);
            messages = messages.concat(result.data.getMessages.messages)
            nextToken = result.data.getMessages.nextToken
            getQuery.variables.nextToken = nextToken
        } while (nextToken && ++count < 3)

        setMessages(messages)
        // Set the Title and Site
        setTitle(result.data.getMessages.site.name);
        setSite(prevSite => {
            return result.data.getMessages.site;
        });

        setReadings(result.data.getMessages.site.readings);

        // Get the last event
        var len = result.data.getMessages.messages.length;
        var lastMessage = result.data.getMessages.site.message;

        lastUpdate = new Date(lastMessage.timestamp * 1000).toLocaleString();
        console.log("length:", len);
        console.log("last message:", lastMessage);
        console.log("last update:", lastUpdate);

        setSpinner(false);
        setShadow(false);
    }

    useEffect(() => {
        let s = new Date();
        let e = new Date();

        console.log("Detail:useEffect:start");
        s.setDate(e.getDate() - 7);
        e.setDate(e.getDate() + 1);

        setStartDate(s);
        setEndDate(e);

        getMessages(s, e, null);

        console.log("Detail:useEffect:end");
    }, [props]);

    const activeStyle = {
        textAlign: 'center',
        fontSize: 16,
        bottom: 5,
        left: 5,
        color: theme.onPrimary
    }

    const appBarStyle = {
        backgroundColor: theme.primary,
        position: 'fixed',
        top: 0,
        width: '100%',
        // padding: 5,
        zIndex: 100,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    };

    const wrapper = {
        width: '100%'
    }

    const backStyle = {
        position: 'absolute',
        top: 0,
        color: 'white'
    }

    const dateStyle = {
        margin: 10
    }

    const gridStyle = {
        // marginTop: 100
    }

    const lastUpdateStyle = {
        textAlign: "center",
        color: theme.onPrimary,
    }

    const radioStyle = {
        marginBottom: 20,
        color: "rgb(96, 96, 96)"
    }

    const spinnerStyle = {
        zIndex: 200
    }

    const titleStyle = {
        color: theme.onPrimary,
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: 28,
        fontWeight: 'bold',
    }

    const filledButtonStyle = {
        margin: "auto",
        marginTop: 20,
        marginBottom: 20
    }

    const downloadIcon = {
        position: 'absolute',
        right: 100,
        top: 0
    };

    const filterIcon = {
        position: 'absolute',
        right: 5,
        top: 0
    };

    const InfoIcon = {
        position: 'absolute',
        right: 50,
        top: 0
    };

    const linkStyle = {
        fontSize: 21,
        textDecoration: 'none',
        color: 'rgb(32,32,32)'
    }

    function getAttributeByPath(obj, path) {
        return path.split('.').reduce((o, key) => (o && o[key] !== undefined) ? o[key] : undefined, obj);
    }

    function onDate() {
        console.log("date field");
    }

    function onDownload() {
        const header = [/*'id', 'site',*/ 'date'];
        const rows = []
        const siteID = site.sk.split('#')[1];
        const name = site.name.replace(",", " ");

        console.log("Detail:Site ID:", siteID);

        // Create the header
        site.readings.map((reading, i) => {
            let readingType = reading.name
            if (readingType == 'Water Level') {
                // header.push('distance')
                header.push('Water Level NAVD88 (ft)')
            } else {
                header.push(readingType);
            }
        })

        console.log("Detail:Header:", header)

        messages.map((message, i) => {
            const msgArray = [/*siteID, name*/]
            var timestamp = new Date(message.msg.timestamp * 1000).toLocaleString()

            timestamp = timestamp.replace(",", " ");
            msgArray.push(timestamp);

            console.log(message.msg);

            site.readings.map((reading, i) => {
                console.log("Reading Path:", reading);
                let value = getAttributeByPath(message, reading.path);
                switch (reading.type) {
                    case "stage":
                        // msgArray.push(+(value / 304.8).toFixed(2))
                        value = (reading.elevation + reading.MLLW - value) / 304.8;
                        break;
                    case "airTemp":
                        value = value * 0.18 + 32;
                        break;
                }
                msgArray.push(+value.toFixed(2));
            })

            // msgArray.push(message.msg.ina219.voltage)
            // msgArray.push(message.msg.senix.temp)
            // msgArray.push(message.msg.lte.rssi)
            // msgArray.push(message.msg.senix.mm)

            rows.push(msgArray)
            // console.log(msgArray)
        })

        const csvContent = [
            header.join(','),                  // Join headers with commas
            ...rows.map(row => row.join(','))   // Join each row with commas
        ].join('\n');                         // Join each line with new line

        // Create a Blob with the file content and type
        const blob = new Blob([csvContent], { type: 'text/plain' });
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = name + '.csv';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function onFilter() {
        if (filter) {
            setFilter(false);
            setSpinner(false);
            setShadow(false);
        } else {
            setFilter(true);
            setShadow(true);
        }
    }

    function onShadow() {
        setShadow(false);
        setFilter(false);
    }

    function onRadioChange(e) {
        var sDate = new Date();
        var eDate = new Date();
        eDate.setHours(0, 0, 0, 0);

        sDate.setDate(eDate.getDate() - e.target.value);
        sDate.setHours(0, 0, 0, 0);

        setStartDate(sDate);
        setEndDate(eDate);

        console.log("onRadioChange:", sDate, eDate, e.target.value);
    };

    function onStartDate(e) {
        // startDate = new Date(e.target.value);
        setStartDate(new Date(e.target.value));
        console.log("onStartDate:", startDate);
    }

    function onEndDate(e) {
        // endDate = new Date(e.target.value);
        setEndDate(new Date(e.target.value));
        console.log("onEndDate:", endDate);
    }

    function onOK(e) {
        console.log("onOK:Start Date:", startDate);
        console.log("onOK:End Date:", endDate);

        getMessages(startDate, endDate);
        setFilter(false);
        console.log("onOK:complete");
    }

    function onCancel(e) {
        setShadow(false);
        setFilter(false);
    }

    function toISOShort(d) {
        return d.toISOString().split('T')[0]
    }

    return (
        <div className="detail" style={wrapper} onClick={props.onClick}>
            <Spinner
                style={spinnerStyle}
                visible={spinner}
            >
            </Spinner>
            <Shadow visible={shadow} onClose={onShadow} />
            <Drawer
                anchor='right'
                visible={filter}
                title="Filter"
                onClose={onFilter}
            >
                <div className={"radio"} onChange={onRadioChange}>
                    <RadioButton value="1" name="filter" label="Today" />
                    <RadioButton value="2" name="filter" label="Last 2 Days" />
                    <RadioButton value="7" name="filter" label="Last 7 Days" />
                    <RadioButton value="14" name="filter" label="Last 14 Days" />
                    <RadioButton value="30" name="filter" label="Last 30 Days" />
                </div>

                <DateField style={dateStyle} onBlur={onStartDate} onChange={onStartDate} value={toISOShort(startDate)}></DateField>
                <DateField style={dateStyle} onBlur={onEndDate} onChange={onEndDate} value={toISOShort(endDate)}></DateField>

                <FilledButton style={filledButtonStyle} onSelect={onOK}>OK</FilledButton>
                <FilledButton style={filledButtonStyle} onSelect={onCancel}>Cancel</FilledButton>
            </Drawer>

            <div className="appbar" style={appBarStyle}>
                <div id="title" style={titleStyle}>{title}</div>
                <div style={lastUpdateStyle}>Updated: {lastUpdate.toLocaleString()}</div>
                <div style={activeStyle} id="active">Status: {site.active ? 'Online' : 'Offline'}</div>

                <BackButton style={backStyle} onSelect={() => navigate(-1)}></BackButton>
                <DownloadButton style={downloadIcon} onSelect={onDownload} />

                <Link style={linkStyle} to={'/Info' + '?id=' + site.sk} >
                    <InfoIconButton style={InfoIcon} />
                </Link>
                <FilterIconButton style={filterIcon} onSelect={onFilter} />

            </div>
            <Grid className="cards detail-cards" style={gridStyle}>
                {
                    site.readings.map((reading, i) => {
                        return (
                            <GridItem key={i}>
                                {Cards(reading.type, reading, site)}
                            </GridItem>
                        )
                    })
                }
            </Grid>
            {
                site.readings.map((reading, i) => {
                    return (
                        Graphs(reading.type, reading, site, messages)
                    )
                })
            }
        </div>
    )
};