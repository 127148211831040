import React  from 'react';
import ReactDOM from "react-dom/client";
import { useState,createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Detail from "./pages/Detail.js";
import GOES from "./pages/GOES.js";
import GOES_Senix from "./pages/GOES_Senix.js";
import GOES_Senix_WS100 from "./pages/GOES_Senix_WS100.js";

import Home from "./pages/Home.js";
import Info from "./pages/Info.js";
import Maxbotix from "./pages/Maxbotix.js";
import MaxbotixWiFi from "./pages/MaxbotixWiFi.js";
import Maxbotix_WS100 from "./pages/Maxbotix_WS100.js";
import Radar from "./pages/Radar.js";
import Radar_WS100 from "./pages/Radar_WS100.js";
import Senix from "./pages/Senix.js";
import Senix_WS100 from "./pages/Senix_WS100.js";
import SiteGrid from "./pages/Sites/Grid.js";
import SitesByCounty from "./pages/SitesByCounty.js";
import SitesByOccupant from "./pages/SitesByOccupant.js";
import SitesTable from "./pages/Sites/Table.js";
import SitesTableByCounty from "./pages/SitesTableByCounty.js";
import WS100 from "./pages/WS100.js";
import WS600 from "./pages/WS600.js";
import NoPage from "./pages/NoPage.js";

import './style.css';

const authObj = {
  loggedIn:true,
  customers:true,
  home:true,
  settings:true
}

const themeObj = {
  primary:'#1991DC',
  primaryVariant:'',
  secondary:'white',
  secondaryVariant:'',
  background:'',
  surface:'',
  error:'#C51162',
  onPrimary:'white',
  onSecondary:'',
  onBackground:'',
  onSurface:'',
  onError:'#FFFFFF'
}

export const AuthContext = createContext(authObj);
export const ThemeContext = createContext(themeObj);
export const urlContext = createContext('https://tvusvjd6afeipodylci64oe5hy.appsync-api.us-east-1.amazonaws.com/graphql');
export const keyContext = createContext("da2-d6ongzxph5gv3knp6uwz4f6e5a")

export default function App() {
  const [auths, setAuths] = useState(authObj);
  const [theme, setTheme] = useState(themeObj);

  return (
    <AuthContext.Provider value={auths}>
    <ThemeContext.Provider value={theme}>
    {console.log("App:path:",window.location.pathname)}
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home update={Math.random()}/>} />
      <Route path="/Detail" element={<Detail update={Math.random()}/>} />
      <Route path="/Info" element={<Info  update={Math.random()} />} />
      <Route path="/GOES" element={<GOES  update={Math.random()} />} />
      <Route path="/GOES_Senix" element={<GOES_Senix  update={Math.random()} />} />
      <Route path="/GOES_Senix_WS100" element={<GOES_Senix_WS100  update={Math.random()} />} />
      <Route path="/Maxbotix" element={<Maxbotix  update={Math.random()} />} />
      <Route path="/MaxbotixWiFi" element={<MaxbotixWiFi update={Math.random()} />} />
      <Route path="/MaxbotixWS100" element={<Maxbotix_WS100 update={Math.random()} />} />
      <Route path="/Radar" element={<Radar  update={Math.random()} />} />
      <Route path="/RadarWS100" element={<Radar_WS100  update={Math.random()} />} />
      <Route path="/Senix" element={<Senix  update={Math.random()} />} />
      <Route path="/SenixWS100" element={<Senix_WS100  update={Math.random()} />} />
      <Route path="/Sites/Grid" element={<SiteGrid update={Math.random()}/>} />
      <Route path="/SitesByCounty" element={<SitesByCounty update={Math.random()}/>} />
      <Route path="/SitesByOccupant" element={<SitesByOccupant update={Math.random()}/>} />
      <Route path="/Sites/Table" element={<SitesTable update={Math.random()}/>} />
      <Route path="/SitesTableByCounty" element={<SitesTableByCounty update={Math.random()}/>} />
      <Route path="/WS100" element={<WS100 update={Math.random()}/>} />
      <Route path="/WS600" element={<WS600 update={Math.random()}/>} />
      <Route path="*" element={<NoPage />} />
    </Routes>
    </BrowserRouter>
    </ThemeContext.Provider>
    </AuthContext.Provider>
  );
}